<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">服务包管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">参数配置</span>
      </div>
    </div>
    <el-card class="box-card">
      <div style="color: #ec808d; text-align: left">
        <p>1、以下参数均以单人自然月的数据进行计算；</p>
        <p>2、系统判定的个税额度根据当月实际发放的金额变动，当月实际发放的金额超过所填写的个税标准额度，就会以对应的个税额度计算报税金额；</p>
        <p>3、实际报税金额占比系数是指每次发放工资时，实际的报税金额占剩余个税额度的比例；</p>
        <p>4、实际报税金额发放占比系数是指每次发放工资时，实际分到相应公司的报税金额占实际报税金额的比例，每种情况下的系数相加必须等于1；</p>
        <p>5、实际报税金额占比系数必须小于1，实际报税金额发放占比系数中每种情况下每个公司的系数都必须大于0并且小于1；</p>
      </div>
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
      >
        <el-row style="width: 100%; text-align: left">
          <el-col :span="11">
            <el-form-item prop="Astandard">
              <span slot="label">
                8000元个税标准
                <span style="font-size: 12px; color: #aabdd6">(3%，包含当前额度，不小于8000)</span>
              </span>
              <el-input v-model="ruleForm.Astandard" type="number" placeholder="请输入8000元个税标准"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="11">
            <el-form-item prop="Bstandard">
              <span slot="label">
                17000元个税标准
                <span style="font-size: 12px; color: #aabdd6">(10%，包含当前额度，不小于17000)</span>
              </span>
              <el-input v-model="ruleForm.Bstandard" type="number" placeholder="请输入17000元个税标准"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="width: 100%; text-align: left">
          <el-col :span="11">
            <el-form-item prop="fax_rate">
              <span slot="label">
                实际报税金额占比系数
                <span style="font-size: 12px; color: #aabdd6">(必须小于1)</span>
              </span>
              <el-input-number
                v-model="ruleForm.fax_rate"
                :min="0"
                :precision="2"
                :step="0.05"
                :max="0.9"
              ></el-input-number>
              <!-- <el-input v-model="ruleForm.fax_rate" type="number" placeholder="请输入实际报税金额占比系数"></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
        <div style="text-align: left">
          <span>实际报税金额发放占比系数</span>
          <span style="color: #aabdd6">
            (
            选择发薪企业共有三种情况，每种情况的系数相加必须等于1，X、A、B、C不代表具体公司，X为交社保的公司中任意一家，A、B、C为可发放大额工资的公司中任意一家
            )
          </span>
        </div>
        <el-row
          style="
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
          "
        >
          <el-col :span="1.5">两家发薪企业：</el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="TwoXenterprise">
              <span slot="label">X公司</span>
              <el-input
                v-model="ruleForm.TwoXenterprise"
                type="number"
                @blur="TwoXrules"
                placeholder="请输入X公司"
                oninput="if(value.length>4)value=value.slice(0,4)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="TwoAenterprise">
              <span slot="label">A公司</span>
              <el-input
                v-model="ruleForm.TwoAenterprise"
                type="number"
                @blur="TwoArules"
                oninput="if(value.length>4)value=value.slice(0,4)"
                placeholder="请输入A公司"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          style="
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
          "
        >
          <el-col :span="1.5">三家发薪企业：</el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="ThreeXenterprise">
              <span slot="label">X公司</span>
              <el-input
                v-model="ruleForm.ThreeXenterprise"
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="ThreeXrules"
                placeholder="请输入X公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="ThreeAenterprise">
              <span slot="label">A公司</span>
              <el-input
                v-model="ruleForm.ThreeAenterprise"
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="ThreeArules"
                placeholder="请输入A公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="ThreeBenterprise">
              <span slot="label">B公司</span>
              <el-input
                v-model="ruleForm.ThreeBenterprise"
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="ThreeBrules"
                placeholder="请输入B公司"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          style="
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
          "
        >
          <el-col :span="1.5">四家发薪企业：</el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="FourXenterprise">
              <span slot="label">X公司</span>
              <el-input
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="FourXrules"
                v-model="ruleForm.FourXenterprise"
                placeholder="请输入X公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="FourAenterprise">
              <span slot="label">A公司</span>
              <el-input
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="FourArules"
                v-model="ruleForm.FourAenterprise"
                placeholder="请输入A公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="FourBenterprise">
              <span slot="label">B公司</span>
              <el-input
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="FourBrules"
                v-model="ruleForm.FourBenterprise"
                placeholder="请输入B公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-form-item prop="FourCenterprise">
              <span slot="label">C公司</span>
              <el-input
                oninput="if(value.length>4)value=value.slice(0,4)"
                type="number"
                @blur="FourCrules"
                v-model="ruleForm.FourCenterprise"
                placeholder="请输入C公司"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div>
          <el-button type="primary" @click="goSubmit('ruleForm')">提 交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const dispatchConfigInfo = api()('dispatch.config.info.json');
export const dispatchConfigEdit = api()('dispatch.config.edit.json');
export default {
  data () {
    var checkAstandard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('8000元个税标准不能为空'));
      }
      setTimeout(() => {
        // if (!Number.isInteger(value)) {
        //   callback(new Error('请输入数字值'));
        // } else {
        if (Number(value) < 8000) {
          callback(new Error('必须大于8000'));
        } else if (Number(value) > 17000) {
          callback(new Error('必须小于17000'));
        } else {
          callback();
        }
        // }
      }, 1000);
    };
    var checkBstandard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('17000元个税标准不能为空'));
      }
      setTimeout(() => {
        if (Number(value) < 17000) {
          callback(new Error('必须大于17000'));
        } else {
          callback();
        }
        // }
      }, 1000);
    };
    return {
      rates2: '',
      rates3: '',
      rates4: '',
      ruleForm: {
        Astandard: '',
        Bstandard: '',
        fax_rate: 0,
        TwoXenterprise: '',
        TwoAenterprise: '',
        ThreeXenterprise: '',
        ThreeAenterprise: '',
        ThreeBenterprise: '',
        FourXenterprise: '',
        FourAenterprise: '',
        FourBenterprise: '',
        FourCenterprise: '',
      },
      rules: {
        Astandard: [{ validator: checkAstandard, trigger: 'blur' }],
        Bstandard: [{ validator: checkBstandard, trigger: 'blur' }],
        fax_rate: [
          {
            required: true,
            message: '请输入实际报税金额占比系数',
            trigger: 'blur',
          },
        ],
        TwoXenterprise: [
          {
            required: true,
            message: '请输入俩家企业X公司系数',
            trigger: 'blur',
          },
        ],
        TwoAenterprise: [
          {
            required: true,
            message: '请输入俩家企业A公司系数',
            trigger: 'blur',
          },
        ],
        ThreeXenterprise: [
          {
            required: true,
            message: '请输入三家企业X公司系数',
            trigger: 'blur',
          },
        ],
        ThreeAenterprise: [
          {
            required: true,
            message: '请输入三家企业A公司系数',
            trigger: 'blur',
          },
        ],
        ThreeBenterprise: [
          {
            required: true,
            message: '请输入三家企业B公司系数',
            trigger: 'blur',
          },
        ],
        FourXenterprise: [
          {
            required: true,
            message: '请输入四家企业X公司系数',
            trigger: 'blur',
          },
        ],
        FourAenterprise: [
          {
            required: true,
            message: '请输入四家企业A公司系数',
            trigger: 'blur',
          },
        ],
        FourBenterprise: [
          {
            required: true,
            message: '请输入四家企业B公司系数',
            trigger: 'blur',
          },
        ],
        FourCenterprise: [
          {
            required: true,
            message: '请输入四家企业C公司系数',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  async mounted () {
    await this.logoData();
  },
  methods: {
    async logoData () {
      const res = await dispatchConfigInfo();
      this.ruleForm.Astandard = res.levels[0].max;
      this.ruleForm.Bstandard = res.levels[1].max;
      this.ruleForm.fax_rate = res.fax_rate;
      this.rates2 = res.rates2;
      this.rates3 = res.rates3;
      this.rates4 = res.rates4;
      this.ruleForm.TwoXenterprise = res.rates2.split(',')[0];
      this.ruleForm.TwoAenterprise = res.rates2.split(',')[1];
      this.ruleForm.ThreeXenterprise = res.rates3.split(',')[0];
      this.ruleForm.ThreeAenterprise = res.rates3.split(',')[1];
      this.ruleForm.ThreeBenterprise = res.rates3.split(',')[2];
      this.ruleForm.FourXenterprise = res.rates4.split(',')[0];
      this.ruleForm.FourAenterprise = res.rates4.split(',')[1];
      this.ruleForm.FourBenterprise = res.rates4.split(',')[2];
      this.ruleForm.FourCenterprise = res.rates4.split(',')[3];
    },
    TwoXrules () {
      if (
        Number(this.ruleForm.TwoXenterprise) >= 1 ||
        Number(this.ruleForm.TwoXenterprise) === 0
      ) {
        this.$message.info('两家发薪企业X公司不得系数大于1等于0');
        this.ruleForm.TwoXenterprise = '';
      }
    },
    TwoArules () {
      let a = Number(this.ruleForm.TwoXenterprise) * 100;
      let b = Number(this.ruleForm.TwoAenterprise) * 100;

      if (b >= 100) {
        this.$message.info('两家发薪企业A公司系数小于1');
        this.ruleForm.TwoAenterprise = '';
      } else if ((a + b) / 100 != 1) {
        this.$message.info('两家发薪企业X公司系数+A公司系数必须等于1');
        this.ruleForm.TwoAenterprise = '';
      } else {
        const arr = [
          this.ruleForm.TwoXenterprise,
          this.ruleForm.TwoAenterprise,
        ];
        this.rates2 = arr.join(',');
      }
    },
    ThreeXrules () {
      if (
        Number(this.ruleForm.ThreeXenterprise) >= 1 ||
        Number(this.ruleForm.ThreeXenterprise) === 0
      ) {
        this.$message.info('三家发薪企业X公司不得系数大于1等于0');
        this.ruleForm.ThreeXenterprise = '';
      } else {
        let a = Number(this.ruleForm.ThreeXenterprise);
        let b = Number(this.ruleForm.ThreeAenterprise);
        let c = Number(this.ruleForm.ThreeBenterprise);
        this.Threerules(a, b, c);
      }
    },
    ThreeArules () {
      if (
        Number(this.ruleForm.ThreeAenterprise) >= 1 ||
        Number(this.ruleForm.ThreeAenterprise) === 0
      ) {
        this.$message.info('三家发薪企业A公司不得系数大于1等于0');
        this.ruleForm.ThreeAenterprise = '';
      } else {
        let a = Number(this.ruleForm.ThreeXenterprise);
        let b = Number(this.ruleForm.ThreeAenterprise);
        let c = Number(this.ruleForm.ThreeBenterprise);
        this.Threerules(a, b, c);
      }
    },
    ThreeBrules () {
      if (
        Number(this.ruleForm.ThreeBenterprise) >= 1 ||
        Number(this.ruleForm.ThreeBenterprise) === 0
      ) {
        this.$message.info('三家发薪企业B公司不得系数大于1等于0');
        this.ruleForm.ThreeAenterprise = '';
      } else {
        let a = Number(this.ruleForm.ThreeXenterprise);
        let b = Number(this.ruleForm.ThreeAenterprise);
        let c = Number(this.ruleForm.ThreeBenterprise);
        this.Threerules(a, b, c);
      }
    },
    Threerules (a, b, c) {
      let z = a * 100;
      let x = b * 100;
      let y = c * 100;
      if (a != 0 && b != 0 && c != 0) {
        if ((x + y + z) / 100 != 1) {
          this.$message.info('三家发薪企业X公司系数+A公司系数+B公司系数等于1');
          this.ruleForm.ThreeBenterprise = '';
        } else {
          const arr = [
            this.ruleForm.ThreeXenterprise,
            this.ruleForm.ThreeAenterprise,
            this.ruleForm.ThreeBenterprise,
          ];
          this.rates3 = arr.join(',');
        }
      }
    },
    FourXrules () {
      if (
        Number(this.ruleForm.FourXenterprise) >= 1 ||
        Number(this.ruleForm.FourXenterprise) === 0
      ) {
        this.$message.info('四家发薪企业X公司不得系数大于1等于0');
        this.ruleForm.FourXenterprise = '';
      } else {
        let a = Number(this.ruleForm.FourXenterprise);
        let b = Number(this.ruleForm.FourAenterprise);
        let c = Number(this.ruleForm.FourBenterprise);
        let d = Number(this.ruleForm.FourCenterprise);
        this.Fourrules(a, b, c, d);
      }
    },
    FourArules () {
      if (
        Number(this.ruleForm.FourAenterprise) >= 1 ||
        Number(this.ruleForm.FourAenterprise) === 0
      ) {
        this.$message.info('四家发薪企业A公司不得系数大于1等于0');
        this.ruleForm.FourAenterprise = '';
      } else {
        let a = Number(this.ruleForm.FourXenterprise);
        let b = Number(this.ruleForm.FourAenterprise);
        let c = Number(this.ruleForm.FourBenterprise);
        let d = Number(this.ruleForm.FourCenterprise);
        this.Fourrules(a, b, c, d);
      }
    },
    FourBrules () {
      if (
        Number(this.ruleForm.FourBenterprise) >= 1 ||
        Number(this.ruleForm.FourBenterprise) === 0
      ) {
        this.$message.info('四家发薪企业B公司不得系数大于1等于0');
        this.ruleForm.FourBenterprise = '';
      } else {
        let a = Number(this.ruleForm.FourXenterprise);
        let b = Number(this.ruleForm.FourAenterprise);
        let c = Number(this.ruleForm.FourBenterprise);
        let d = Number(this.ruleForm.FourCenterprise);
        this.Fourrules(a, b, c, d);
      }
    },
    FourCrules () {
      if (
        Number(this.ruleForm.FourCenterprise) >= 1 ||
        Number(this.ruleForm.FourCenterprise) === 0
      ) {
        this.$message.info('四家发薪企业A公司不得系数大于1等于0');
        this.ruleForm.FourCenterprise = '';
      } else {
        let a = Number(this.ruleForm.FourXenterprise);
        let b = Number(this.ruleForm.FourAenterprise);
        let c = Number(this.ruleForm.FourBenterprise);
        let d = Number(this.ruleForm.FourCenterprise);
        this.Fourrules(a, b, c, d);
      }
    },
    Fourrules (a, b, c, d) {
      let z = a * 100;
      let x = b * 100;
      let y = c * 100;
      let w = d * 100;
      if (a != 0 && b != 0 && c != 0 && d != 0) {
        if ((x + y + z + w) / 100 != 1) {
          this.$message.info(
            '四家发薪企业X公司系数+A公司系数+B公司系数+C公司系数等于1',
          );
          this.ruleForm.FourCenterprise = '';
        } else {
          const arr = [
            this.ruleForm.FourXenterprise,
            this.ruleForm.FourAenterprise,
            this.ruleForm.FourBenterprise,
            this.ruleForm.FourCenterprise,
          ];
          this.rates4 = arr.join(',');
        }
      }
    },
    goSubmit (formName) {
      const levels = [
        { max: Number(this.ruleForm.Astandard), tax: 8000 },
        { max: Number(this.ruleForm.Bstandard), tax: 17000 },
      ];
      console.log(levels);
      Number(this.ruleForm.FourCenterprise);
      if (this.ruleForm.fax_rate === 0) {
        this.$message.info('实际报税金额占比系数不得等于0');
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const parent = {
            fax_rate: this.ruleForm.fax_rate.toString(),
            levels: levels,
            rates2: this.rates2,
            rates3: this.rates3,
            rates4: this.rates4,
          };
          const res = dispatchConfigEdit(parent);
          this.$message.success('编辑成功');
          this.logoData();
          console.log(res);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="sass"></style>
